import axios from "axios";
import refresh from "./refresh";
export default async function getFormsUserId(
  cookies,
  setCookie,
  page,
  setData,
  data,
  add,
  userId,
  setMaxPage
) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/forms?page=${page}&per_page=25&sort=id&order=asc&entities=conversion&entities=conversion.stats`,
        { headers }
      )
      .then((res) => {
        if (res.status == 200) {
          setMaxPage(res.data["max_page"]);
          const transformedNewData = res.data["results"].map((item) => ({
            ...item,
            sent: item["stats"]?.["sent"],
            pending: item["stats"]?.["pending"],
            approved: item["stats"]?.["approved"],
            rejected: item["stats"]?.["rejected"],
          }));
          setData(
            add ? [[...data, transformedNewData].flat(1)] : transformedNewData
          );
        } else if (res.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getFormsUserId(
                cookies,
                setCookie,
                page,
                setData,
                data,
                add,
                userId,
                setMaxPage
              );
            else setData([]);
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        if (e.response.status == 401)
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getFormsUserId(
                cookies,
                setCookie,
                page,
                setData,
                data,
                add,
                userId,
                setMaxPage
              );
            else setData([]);
          });
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT)
        getFormsUserId(
          cookies,
          setCookie,
          page,
          setData,
          data,
          add,
          userId,
          setMaxPage
        );
      else setData([]);
    });
  }
}
