import "./App.css";
import { Routes, Route } from "react-router-dom";
import PageLayout from "./components/PageLayout";

import HopperPage from "./pages/HopperPage";
import NotfoundPage from "./pages/NotFoundPage";
import BroadcastPage from "./pages/BroadcastPage";
import ExportPage from "./pages/ExportPage";
import StatsPage from "./pages/StatsPage";
import UsersListPage from "./pages/UsersListPage";
import TrafficPage from "./pages/TrafficPage";
import ApplicationPage from "./pages/ApplicationPage";
import UserPage from "./pages/userPage";
import Auth from "./pages/Auth";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<PageLayout />}>
          <Route index element={<HopperPage />} />
          <Route path="broadcast" element={<BroadcastPage />} />
          <Route path="export" element={<ExportPage />} />
          <Route path="app-stats" element={<StatsPage />} />
          <Route path="users-list" element={<UsersListPage />} />
          <Route path="traffic" element={<TrafficPage />} />
          <Route path="hopper" element={<HopperPage />} />
          <Route path="app/:id" element={<ApplicationPage />} />
          <Route path="user/:id" element={<UserPage />} />
        </Route>
        <Route path="auth" element={<Auth />} />

        <Route path="*" element={<NotfoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
