import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function LineChart({ label, datasets, labels }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: label,
      },
    },
  };

  function getTomorrowsDate(dateString, i) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + i);

    console.log(date.getDate());
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [finalData, setFinalData] = useState(datasets);
  const [finalLabels, setFinalLabels] = useState(labels);

  useEffect(() => {
    setFinalData(datasets);
    setFinalLabels(labels);
    console.warn(finalLabels, labels);
  }, [datasets, labels]);

  useEffect(() => {
    const final_data1 = finalData;
    final_data1.forEach((dataset) => {
      if (dataset.data.length == 1) {
        if (finalLabels.length == 1) {
          const final_labels1 = [finalLabels[0], finalLabels[0]];
          setFinalLabels(final_labels1);
        }

        dataset.data.push(dataset.data[0]);
      }
    });
    setFinalData(final_data1);
  }, [finalData]);

  const data = {
    labels: finalLabels,
    datasets: finalData,
  };
  return (
    <div style={{ width: "80%" }}>
      <Line options={options} data={data} />
    </div>
  );
}
