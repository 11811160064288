import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useState, useEffect } from "react";
import getUserId from "../api/getUserId";
import getFormsUserId from "../api/getFormsUserId";
import CustomTable from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Button from "@mui/material/Button";

function UserPage() {
  const { id } = useParams();
  const [data, setData] = useState();
  const [forms, setForms] = useState([]);
  const [cookies, setCookie] = useCookies(["JWT"]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const labels = {
    id: "ID анкеты",
    sent: "Создано заявок",
    pending: "Ожидает",
    approved: "Одобрено",
    rejected: "Отклонено",
  };

  useEffect(() => {
    getUserId(cookies, setCookie, id, setData, data);
  }, [id]);

  useEffect(() => {
    getFormsUserId(
      cookies,
      setCookie,
      page,
      setForms,
      forms,
      true,
      id,
      setMaxPage
    );
  }, [id, page]);

  useEffect(() => {
    console.log(forms);
  }, [forms]);
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };
  return (
    <div style={{ textAlign: "start" }}>
      <ListItemIcon
        onClick={() => handleBackClick()}
        sx={{ textAlign: "start" }}
      >
        <ArrowBackIosNewIcon fontSize="medium" /> Назад
      </ListItemIcon>
      <div style={{ textAlign: "left" }}>
        <p>ID: {data?.id}</p>
        <p>TG username: {data?.username}</p>
        <p>Имя: {data?.first_name}</p>
        <p>Фамилия: {data?.last_name}</p>
        <p>
          Дата регистрации: {new Date(data?.created_at).toLocaleDateString()}
        </p>
        <p>
          Дата обновления: {new Date(data?.updated_at).toLocaleDateString()}
        </p>
        <p>TG ID: {data?.telegram_id}</p>
        <p>Пройдена каптча: {data?.is_captcha_passed ? "Да" : "Нет"}</p>
      </div>
      {forms.length > 0 ? (
        forms[0].length ? (
          <CustomTable
            touchable={true}
            to="app"
            labels={labels}
            data={forms.length > 0 ? forms[0] : []}
          />
        ) : (
          <h1>Нет анкет</h1>
        )
      ) : (
        <h1>Нет анкет</h1>
      )}
      {page < maxPage ? (
        <Button
          sx={{ marginTop: "40px" }}
          size="large"
          onClick={() => setPage(page + 1)}
          variant="outlined"
        >
          Загрузить еще
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default UserPage;
