import axios from "axios";
import refresh from "./refresh";

const formatDate = (dateString, start = true) => {
  console.log(dateString.split("T")[0]);
  return (
    dateString.split("T")[0] + (start ? "T00:00:00.000Z" : "T23:59:59.000Z")
  );
};

export default async function getForms(
  cookies,
  setCookie,
  page,
  setData,
  data,
  add,
  selectionRange,
  setMaxPage
) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    await axios
      .get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/form/list?page=${page}&per_page=25&sort=id&order=asc&entities=conversion&entities=conversion.stats${
          selectionRange.startDate
            ? `&start_date=${formatDate(
                new Date(selectionRange.startDate).toISOString()
              )}`
            : ""
        }${
          selectionRange.endDate
            ? `&end_date=${formatDate(
                new Date(selectionRange.endDate).toISOString(),
                false
              )}`
            : ""
        }`,
        { headers }
      )
      .then((res) => {
        if (res.status == 200) {
          setMaxPage(res.data["max_page"]);
          const transformedNewData = res.data["results"].map((item) => ({
            ...item,
            sent: item["stats"]?.["sent"],
            pending: item["stats"]?.["pending"],
            approved: item["stats"]?.["approved"],
            rejected: item["stats"]?.["rejected"],
          }));
          setData(add ? [...data, ...transformedNewData] : transformedNewData);
        } else if (res.status == 401) {
          console.log("unauthorized");
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getForms(
                cookies,
                setCookie,
                page,
                setData,
                data,
                add,
                selectionRange,
                setMaxPage
              );
            else setData([]);
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        if (e.response.status == 401)
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getForms(
                cookies,
                setCookie,
                page,
                setData,
                data,
                add,
                selectionRange,
                setMaxPage
              );
            else setData([]);
          });
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT)
        getForms(
          cookies,
          setCookie,
          page,
          setData,
          data,
          add,
          selectionRange,
          setMaxPage
        );
      else setData([]);
    });
  }
}
