import axios from "axios";
import refresh from "./refresh";
import getProgressState from "./getProgressState";

const formatDate = (dateString, start = true) => {
  console.log(dateString.split("T")[0]);
  return (
    dateString.split("T")[0] + (start ? "T00:00:00.000Z" : "T23:59:59.000Z")
  );
};

export default async function getProgress(
  cookies,
  setCookie,
  setData,
  data,
  selectionRange
) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    await axios
      .get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/statistics/form/progress/date-range${
          selectionRange.startDate
            ? `?start_date=${formatDate(
                new Date(selectionRange.startDate).toISOString()
              )}`
            : ""
        }${
          selectionRange.endDate
            ? `&end_date=${formatDate(
                new Date(selectionRange.endDate).toISOString(),
                false
              )}`
            : ""
        }`,
        { headers }
      )
      .then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          getProgressState(cookies.JWT)
            .then((states) => {
              const res1 = {};
              console.log(states);
              for (let i = 1; i < 25; i++) {
                if (
                  Object.values(res.data).filter((obj) => obj.progress == i)
                    .length
                ) {
                  res1[states[i]] = Object.values(res.data).filter(
                    (obj) => obj.progress == i
                  )[0].count;
                } else res1[states[i]] = 0;
              }
              const data = {
                labels: Object.keys(res1),
                datasets: [
                  {
                    label: "Пользователи",
                    data: Object.values(res1),
                    backgroundColor: "rgba(255, 99, 132, 0.5)",
                  },
                ],
              };
              setData(data);
            })
            .catch((e) => {});
        } else if (res.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getProgress(cookies, setCookie, setData, data, selectionRange);
            else setData([]);
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        if (e.response.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getProgress(cookies, setCookie, setData, data, selectionRange);
            else setData([]);
          });
        }
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT)
        getProgress(cookies, setCookie, setData, data, selectionRange);
      else setData([]);
    });
  }
}
