import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import getCpa from "../api/getCpa";
import { useCookies } from "react-cookie";
import getContractors from "../api/getContractors";
import CustomTable from "../components/CustomTable";
import Selector from "../components/Selector";
import CustomDateRangePicker from "../components/DateRangePicker";
import getFormId from "../api/getFormId";
import CustomTabs from "../components/Tabs";
import getCpaChart from "../api/getCpaChart";
import { LineChart } from "../components/LineChart";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { useNavigate } from "react-router-dom";
function ChartEl({ selectionRange, setSelectionRange }) {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [cookies, setCookie] = useCookies(["JWT"]);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  useEffect(() => {
    getCpaChart(
      cookies,
      setCookie,
      setData,
      data,
      selectionRange,
      setSelectionRange,
      id
    );
  }, [selectionRange, setSelectionRange]);

  useEffect(() => {
    setLabels(Object.keys(data));
    const sent = Object.values(data).map((obj) =>
      obj["sent"] ? obj["sent"] : 0
    );
    const approved = Object.values(data).map((obj) =>
      obj["approved"] ? obj["approved"] : 0
    );
    const rejected = Object.values(data).map((obj) =>
      obj["rejected"] ? obj["rejected"] : 0
    );
    const submitted = Object.values(data).map((obj) =>
      obj["submitted"] ? obj["submitted"] : 0
    );
    const pending = Object.values(data).map((obj) =>
      obj["pending"] ? obj["pending"] : 0
    );
    setDatasets([
      {
        label: "Общее кол-во заявок",
        data: sent,
        borderColor: "rgb(199, 228, 42)",
        backgroundColor: "rgba(199, 228, 42, 0.5)",
      },
      {
        label: "Кол-во заявок в ожидании",
        data: pending,
        borderColor: "rgb(203, 62, 175)",
        backgroundColor: "rgba(203, 62, 175, 0.5)",
      },
      {
        label: "Кол-во одобренных заявок",
        data: approved,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Кол-во выданных заявок",
        data: submitted,
        borderColor: "rgb(149, 235, 109)",
        backgroundColor: "rgba(149, 235, 109, 0.5)",
      },
      {
        label: "Кол-во отвергнутых заявок",
        data: rejected,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ]);
  }, [data]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <LineChart
        label="Статистика по заявкам"
        datasets={datasets}
        labels={labels}
      />
    </div>
  );
}

function TableEl({ selectionRange, setSelectionRange }) {
  const { id } = useParams();

  const labels = {
    id: "ID заявки",
    contractor: "Контрагент",
    status: "Статус",
    updated_at: "Время изм. статуса",
  };

  const statusOptions = [
    { id: 1, name: "Принята" },
    { id: 2, name: "Ожидает" },
    { id: 3, name: "Отклонена" },
  ];
  const [data, setData] = useState([]);
  const [cookies, setCookie] = useCookies(["JWT"]);
  const [page, setPage] = useState(1);
  const [contractors, setContractors] = useState([]);
  const [contractor, setContractor] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    getCpa(id, cookies, setCookie, setData, data, true, contractors);
  }, [page, contractors]);

  useEffect(() => {
    getCpa(id, cookies, setCookie, setData, data, false, contractors);
  }, [contractor, status, selectionRange]);

  useEffect(() => {
    getContractors(cookies, setCookie, setContractors, contractors);
  }, []);

  useEffect(() => {
    console.log(data.flat(1));
  }, [data]);

  return (
    <div style={{ paddingBottom: 400 }}>
      {/* {Object.entries(data).flatMap(([key, array]) => { return array.map(obj => ({ ...obj, key })); }).length > 0 ? <CustomTable touchable={false} to='cpa' labels={labels} data={Object.entries(data).flatMap(([key, array]) => { return array.map(obj => ({ ...obj, key })); })} /> : <h1>Нет заявок</h1>} */}
      {data.flat(1).length > 0 ? (
        <CustomTable
          touchable={false}
          to="cpa"
          labels={labels}
          data={data.flat(1)}
        />
      ) : (
        <h1>Нет заявок</h1>
      )}
    </div>
  );
}

function ApplicationPage() {
  const { id } = useParams();

  const labels = ["Таблица", "График"];
  const navigate = useNavigate();
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });

  const statusOptions = [
    { id: 1, name: "Принята" },
    { id: 2, name: "Ожидает" },
    { id: 3, name: "Отклонена" },
  ];
  const [cookies, setCookie] = useCookies(["JWT"]);
  const [contractors, setContractors] = useState([]);
  const [contractor, setContractor] = useState([]);
  const [appData, setAppData] = useState({});
  const [status, setStatus] = useState("");

  useEffect(() => {
    getFormId(id, cookies, setCookie, setAppData);
  }, []);

  useEffect(() => {
    getContractors(cookies, setCookie, setContractors, contractors);
  }, []);

  useEffect(() => {
    console.log(contractors);
  }, [contractors]);

  const handleBackClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const elements = [TableEl, ChartEl];

  console.log(appData);
  return (
    <div style={{ textAlign: "start" }}>
      <ListItemIcon
        onClick={() => handleBackClick()}
        sx={{ textAlign: "start" }}
      >
        <ArrowBackIosNewIcon fontSize="medium" /> Назад
      </ListItemIcon>
      <div style={{ width: "80%", textAlign: "start" }}>
        <p>ID Анкеты: {id}</p>
        {appData?.created_at ? (
          <p>
            Дата создания: {new Date(appData?.created_at).toLocaleDateString()}{" "}
            {new Date(appData?.created_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}
        {appData?.updated_at ? (
          <p>
            Дата обновления:{" "}
            {new Date(appData?.updated_at).toLocaleDateString()}{" "}
            {new Date(appData?.updated_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}
        <p>Отправлена: {appData?.sent ? "Да" : "Нет"}</p>
        {appData?.sent_at ? (
          <p>
            Дата отправки: {new Date(appData?.sent_at).toLocaleDateString()}{" "}
            {new Date(appData?.sent_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}
        <p>Завершена: {appData?.completed ? "Да" : "Нет"}</p>
        {appData?.completed_at ? (
          <p>
            Дата завершения:{" "}
            {new Date(appData?.completed_at).toLocaleDateString()}{" "}
            {new Date(appData?.completed_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}

        <p>Активна: {appData?.is_live ? "Да" : "Нет"}</p>
        {appData?.live_at ? (
          <p>
            Дата активности: {new Date(appData?.live_at).toLocaleDateString()}{" "}
            {new Date(appData?.live_at).toLocaleTimeString()}
          </p>
        ) : (
          <></>
        )}
        <br />
        <p>Текущий прогресс заполнения: {appData?.progress?.current}</p>
        <p>Общий прогресс заполнения: {appData?.progress?.total}</p>
        <p>Максимальный этап: {appData?.progress?.max_current}</p>
        <p>
          Заполнение окончено: {appData?.progress?.is_finished ? "Да" : "Нет"}
        </p>
        <br />
        <p>Серия паспорта: {appData?.data?.passport_series}</p>
        <p>Номер паспорта: {appData?.data?.passport_number}</p>
        <p>Паспорт выдан: {appData?.data?.passport_issued_name}</p>
        <p>Код отдела выдачи паспорта: {appData?.data?.passport_issued_code}</p>
        <p>
          Дата выдачи паспорта:{" "}
          {new Date(appData?.data?.passport_issued_date).toLocaleDateString()}
        </p>
        <p>ИНН: {appData?.data?.inn}</p>
        <p>СНИЛС: {appData?.data?.snils}</p>
        <br />
        <p>Имя: {appData?.data?.fio?.first_name}</p>
        <p>Фамилия: {appData?.data?.fio?.last_name}</p>
        <p>Отчество: {appData?.data?.fio?.patronymic}</p>
        <p>Пол: {appData?.data?.gender == "female" ? "Женский" : "Мужской"}</p>
        <p>
          Дата рождения:{" "}
          {new Date(appData?.data?.birth_date).toLocaleDateString()}
        </p>
        <p>Телефон: {appData?.data?.phone}</p>
        <p>Email: {appData?.data?.email}</p>

        <br />
        <p>Базовый доход: {appData?.data?.basic_income}</p>
        <p>Дополнительный доход: {appData?.data?.add_income}</p>
        <p>Платежи по кредитам: {appData?.data?.credit_payments}</p>
        <p>Траты на жилье: {appData?.data?.house_expenses}</p>
        <p>Траты на еду: {appData?.data?.food_expenses}</p>
        <p>Прочие траты: {appData?.data?.other_expenses}</p>

        <br />
        <p>Сумма займа: {appData?.data?.loan_amount}</p>
        <p>Срок займа: {appData?.data?.loan_days} дн.</p>

        <br />

        <h2>Адрес организации</h2>
        <p>Название организации: {appData?.data?.organization_name}</p>
        <p>Контактный телефон: {appData?.data?.organization_phone}</p>
        <p>Область (регион): {appData?.data?.organization_address?.region}</p>
        <p>Город: {appData?.data?.organization_address?.city}</p>
        <p>Улица: {appData?.data?.organization_address?.street}</p>
        <p>Дом: {appData?.data?.organization_address?.house}</p>
        <p>Корпус: {appData?.data?.organization_address?.housing}</p>
        <p>Квартира: {appData?.data?.organization_address?.apartment}</p>

        <br />

        <h2>Регистрационный адрес</h2>
        <p>Область (регион): {appData?.data?.registration_address?.region}</p>
        <p>Город: {appData?.data?.registration_address?.city}</p>
        <p>Улица: {appData?.data?.registration_address?.street}</p>
        <p>Дом: {appData?.data?.registration_address?.house}</p>
        <p>Корпус: {appData?.data?.registration_address?.housing}</p>
        <p>Квартира: {appData?.data?.registration_address?.apartment}</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "80%" }}>
          <CustomTabs
            selectionRange={selectionRange}
            setSelectionRange={contractor}
            labels={labels}
            elements={elements}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "flex-end",
            marginTop: 60,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
            }}
          >
            <Selector
              label="Контрагенты"
              options={contractors}
              option={contractor}
              setOption={setContractor}
            />
            {/* <Selector
              label="Статус"
              options={statusOptions}
              option={status}
              setOption={setStatus}
            /> */}
          </div>

          <CustomDateRangePicker
            selectionRange={selectionRange}
            setSelectionRange={setSelectionRange}
          />
        </div>
      </div>
    </div>
  );
}

export default ApplicationPage;
