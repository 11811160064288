import Button from "@mui/material/Button";
import { useCookies } from "react-cookie";

function ExportPage() {
  const [cookies, setCookie] = useCookies(["JWT"]);
  const handleExport = async () => {
    try {
      // Fetch the file from the backend
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/statistics/xlsx`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/octet-stream",
            Authorization: `Bearer ${cookies.JWT}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // Convert the response into a Blob
      const blob = await response.blob();

      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element
      const a = document.createElement("a");
      a.href = url;
      a.download = "cpa-ad-stats.xlsx"; // Set the desired file name and extension
      document.body.appendChild(a);

      // Programmatically click the anchor element to trigger the download
      a.click();

      // Clean up
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  return (
    <div>
      <Button size="large" onClick={() => handleExport()} variant="outlined">
        Экспорт
      </Button>
    </div>
  );
}

export default ExportPage;
