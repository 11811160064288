import axios from "axios";

export default async function getProgressState(jwt) {
  const headers = {
    accept: "application/json",
    Authorization: `Bearer ${jwt}`,
  };
  return await axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/cpa/form-states`, { headers })
    .then((res) => {
      return res.data;
    })
    .catch((e) => {});
}
