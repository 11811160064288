import { useEffect, useState } from "react";
import CustomTable from "../components/CustomTable";
import getUsers from "../api/getUsers";
import { useCookies } from "react-cookie";
import Button from "@mui/material/Button";

function UsersListPage() {
  const labels = {
    id: "ID пользователя",
    fullname: "ФИО",
    username: "TG",
    createdAt: "Дата регистрации",
  };
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [cookies, setCookie] = useCookies(["JWT"]);

  useEffect(() => {
    getUsers(cookies, setCookie, page, setData, data, setMaxPage);
  }, [page]);
  console.log(data);
  return (
    <div>
      <p style={{ textAlign: "start", marginLeft: 10 }}>
        Всего пользователей: {data.length}
      </p>
      {data.length > 0 ? (
        <CustomTable touchable={true} to="user" labels={labels} data={data} />
      ) : (
        <h1>Нет пользователей</h1>
      )}
      {page < maxPage ? (
        <Button
          sx={{ marginTop: "40px" }}
          size="large"
          onClick={() => setPage(page + 1)}
          variant="outlined"
        >
          Загрузить еще
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default UsersListPage;
