import CustomTable from "../components/CustomTable";
import CustomDateRangePicker from "../components/DateRangePicker";
import { LineChart } from "../components/LineChart";
import Selector from "../components/Selector";
import CustomTabs from "../components/Tabs";
import { useState, useEffect } from "react";
import faker from "faker";
import { useCookies } from "react-cookie";
import getForms from "../api/getForms";
import getContractors from "../api/getContractors";
import Button from "@mui/material/Button";

function TableEl({ selectionRange, setSelectionRange }) {
  const labels = {
    id: "ID анкеты",
    sent: "Создано заявок",
    pending: "Ожидает",
    approved: "Одобрено",
    rejected: "Отклонено",
  };

  const statusOptions = [
    { id: 1, name: "Принята" },
    { id: 2, name: "Ожидает" },
    { id: 3, name: "Отклонена" },
  ];

  const [data, setData] = useState([]);
  const [cookies, setCookie] = useCookies(["JWT"]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [contractors, setContractors] = useState([]);
  const [contractor, setContractor] = useState("");

  const [status, setStatus] = useState("");

  // useEffect(() => { console.log(data) }, [data])

  useEffect(() => {
    getForms(
      cookies,
      setCookie,
      page,
      setData,
      data,
      true,
      selectionRange,
      setMaxPage
    );
  }, [page]);

  useEffect(() => {
    getForms(
      cookies,
      setCookie,
      page,
      setData,
      data,
      false,
      selectionRange,
      setMaxPage
    );
  }, [contractor, status, selectionRange]);

  useEffect(() => {
    getContractors(cookies, setCookie, setContractors, contractors);
  }, []);

  console.log(contractors);
  return (
    <div>
      {data.length > 0 ? (
        <CustomTable touchable={true} to="app" labels={labels} data={data} />
      ) : (
        <h1>Нет анкет</h1>
      )}
      {page < maxPage ? (
        <Button
          sx={{ marginTop: "40px" }}
          size="large"
          onClick={() => setPage(page + 1)}
          variant="outlined"
        >
          Загрузить еще
        </Button>
      ) : (
        <></>
      )}
    </div>
  );
}

function StatsPage() {
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ width: "80%" }}>
        <TableEl
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
        />
      </div>

      <CustomDateRangePicker
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
    </div>
  );
}

export default StatsPage;
