import { useEffect, useState } from "react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import auth from "../api/auth";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

function Auth() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errUser, setErrUser] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [errBelow, setErrBelow] = useState("");

  const [cookies, setCookie] = useCookies(["JWT"]); // как я понял, указываем интересующие нас куки (?)
  const [redirect, setRedirect] = useState(false);
  const [guest, setGuest] = useState(true);

  useEffect(() => {
    if (cookies.JWT != null || cookies.Session != null) {
      setGuest(false);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault(); // чтоб форма не сбросилась к хуям в теории
    if (username == "") setErrUser("Это обязательное поле!");
    if (password == "") setErrPassword("Это обязательное поле!");
    if (username != "" && password != "") {
      auth(setCookie, username, password)
        .then((res) => {
          console.log(res);
          if (res) setRedirect(true);
          else setErrBelow("Неправильный логин или пароль!");
        })
        .catch((e) => setErrBelow("Error: ", e));
    }
  };

  function handleUsername(value) {
    setUsername(value);
    if (errUser != "") setErrUser("");
    if (errBelow != "") setErrBelow("");
  }

  function handlePassword(value) {
    setPassword(value);
    if (errPassword != "") setErrPassword("");
    if (errBelow != "") setErrBelow("");
  }

  return (
    <div className="App">
      {guest ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "25%",
            // alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {redirect && <Navigate replace to="/" />}
          <h1>login</h1>
          <TextField
            error={errUser}
            style={{ borderColor: errUser == "" ? "#aaa" : "#F00" }}
            value={username}
            type="email"
            onChange={(e) => handleUsername(e.target.value)}
            variant="outlined"
            sx={{
              "& .Mui-error": {
                "& fieldset": {
                  borderColor: "green",
                },
              },
            }}
          />
          <p style={{ display: errUser == "" ? "none" : "block" }}>{errUser}</p>
          <TextField
            error={errPassword}
            style={{
              borderColor: errPassword == "" ? "#aaa" : "#F00",
              marginTop: 20,
            }}
            value={password}
            type="password"
            onChange={(e) => handlePassword(e.target.value)}
          />
          <p style={{ display: errPassword == "" ? "none" : "block" }}>
            {errPassword}
          </p>
          <div style={{ height: 100 }} />
          <p style={{ display: errBelow == "" ? "none" : "block" }}>
            {errBelow}
          </p>
          <Button
            size="large"
            variant="contained"
            style={{ width: "min-content", alignSelf: "center" }}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Войти
          </Button>
        </div>
      ) : (
        // if user somehow accessed /login while already logged in
        <div>
          <h1>Вы уже вошли в аккаунт!</h1>
          <Button
            size="large"
            variant="outlined"
            onClick={() => {
              setGuest(true);
              setRedirect(true);
            }}
          >
            На главную
          </Button>
        </div>
      )}
    </div>
  );
}

export default Auth;
