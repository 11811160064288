import axios from "axios";
import refresh from "./refresh";

export default async function getContractors(
  cookies,
  setCookie,
  setData,
  data
) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/cpa/contractors`, { headers })
      .then((res) => {
        if (res.status == 200) {
          // console.log(res.data.map((obj) => { return { id: obj['id'], name: obj['name'] } }))
          setData(
            res.data.map((obj) => {
              return { id: obj["id"], name: obj["name"] };
            })
          );
        } else if (res.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT) getContractors(cookies, setCookie, setData, data);
            else setData([]);
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        if (e.response.status == 401)
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT) getContractors(cookies, setCookie, setData, data);
            else setData([]);
          });
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT) getContractors(cookies, setCookie, setData, data);
      else setData([]);
    });
  }
}
