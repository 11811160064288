import CustomDateRangePicker from "../components/DateRangePicker";
import { LineChart } from "../components/LineChart";
import Selector from "../components/Selector";
import CustomTabs from "../components/Tabs";
import faker from "faker";
import getIncomingTraffic from "../api/getIncomingTraffic";
import { useState, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import getCpaChart from "../api/getCpaChart";
import getContractors from "../api/getContractors";

function OutcomingTraffic() {
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });
  const [data, setData] = useState({});
  const [cookies, setCookie] = useCookies(["JWT"]);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [contractors, setContractors] = useState([]);
  const [contractor, setContractor] = useState([]);
  useEffect(() => {
    getContractors(cookies, setCookie, setContractors, contractors);
  }, []);
  useEffect(() => {
    getCpaChart(cookies, setCookie, setData, data, selectionRange, contractor);
  }, [selectionRange, contractor]);

  useEffect(() => {
    setLabels(Object.keys(data));
    const sent = Object.values(data).map((obj) =>
      obj["sent"] ? obj["sent"] : 0
    );
    const approved = Object.values(data).map((obj) =>
      obj["approved"] ? obj["approved"] : 0
    );
    const rejected = Object.values(data).map((obj) =>
      obj["rejected"] ? obj["rejected"] : 0
    );
    const submitted = Object.values(data).map((obj) =>
      obj["submitted"] ? obj["submitted"] : 0
    );
    const pending = Object.values(data).map((obj) =>
      obj["pending"] ? obj["pending"] : 0
    );
    setDatasets([
      {
        label: "Общее кол-во заявок",
        data: sent,
        borderColor: "rgb(199, 228, 42)",
        backgroundColor: "rgba(199, 228, 42, 0.5)",
      },
      {
        label: "Кол-во заявок в ожидании",
        data: pending,
        borderColor: "rgb(203, 62, 175)",
        backgroundColor: "rgba(203, 62, 175, 0.5)",
      },
      {
        label: "Кол-во одобренных заявок",
        data: approved,
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Кол-во выданных заявок",
        data: submitted,
        borderColor: "rgb(149, 235, 109)",
        backgroundColor: "rgba(149, 235, 109, 0.5)",
      },
      {
        label: "Кол-во отвергнутых заявок",
        data: rejected,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ]);
  }, [data]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <LineChart label="Исходящий трафик" datasets={datasets} labels={labels} />
      <div
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          justifyContent: "flex-end",
          marginTop: 60,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          <Selector
            label="Контрагенты"
            options={contractors}
            option={contractor}
            setOption={setContractor}
          />
          {/* <Selector
              label="Статус"
              options={statusOptions}
              option={status}
              setOption={setStatus}
            /> */}
        </div>

        <CustomDateRangePicker
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
        />
      </div>
    </div>
  );
}

function IncomingTraffic() {
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });

  const [data, setData] = useState({});
  const [cookies, setCookie] = useCookies(["JWT"]);
  const [labels, setLabels] = useState([]);
  const [datasets, setDatasets] = useState([]);
  useEffect(() => {
    console.log(selectionRange);
    getIncomingTraffic(cookies, setCookie, setData, data, selectionRange);
  }, [selectionRange]);

  useEffect(() => {
    setLabels(Object.keys(data));
    setDatasets([
      {
        label: "Новые пользователи",
        data: Object.values(data).map((obj) =>
          obj.length > 0
            ? obj.filter((val) => val.type == "user_created")[0].count
            : 0
        ),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ]);
  }, [data]);

  useEffect(() => {
    console.warn(selectionRange);
  }, [selectionRange]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <LineChart label="Входящий трафик" datasets={datasets} labels={labels} />
      <CustomDateRangePicker
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
    </div>
  );
}

function TrafficPage() {
  const labels = ["Входящий трафик", "Исходящий трафик"];
  const elements = [IncomingTraffic, OutcomingTraffic];
  return (
    <div>
      <CustomTabs labels={labels} elements={elements} />
    </div>
  );
}

export default TrafficPage;
