import axios from "axios";

export default async function auth(setCookie, username, password) {
  const data = {
    username: username,
    password: password,
  };

  const headers = {
    accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
  };
  return await axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, data, { headers })
    .then((response) => {
      if (response.status == 200) {
        document.cookie =
          "JWT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // clear cookies
        setCookie("JWT", response.data["access_token"], { path: "/" });
        return true;
      }
    })
    .catch((e) => console.error(e));
}
