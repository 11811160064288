import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Selector({ label, options, option, setOption }) {
  // const [option, setOption] = React.useState("");

  const handleChange = (event) => {
    console.warn(option === options, option, options);

    if (event.target.value.includes("all")) {
      if (options !== option) {
        event.target.value = [];
        setOption(options);
      } else {
        setOption([]);
      }
    } else {
      setOption(event.target.value);
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={option}
          multiple
          onChange={handleChange}
          label={label}
        >
          <MenuItem value={"all"}>
            <em>Все</em>
          </MenuItem>
          {options.map((text) => {
            return <MenuItem value={text}>{text.name}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </div>
  );
}
