import { BarChart } from "../components/BarChart";
import { useState, useEffect } from "react";
import CustomDateRangePicker from "../components/DateRangePicker";
import { useCookies } from "react-cookie";
import getProgress from "../api/getHopper";

function HopperPage() {
  const endDate = new Date();
  endDate.setHours(23);
  endDate.setMinutes(59);
  endDate.setSeconds(59);
  endDate.setMilliseconds(59);
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
    endDate: endDate,
    key: "selection",
  });
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: "Пользователи",
        data: [],
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });
  const [cookies, setCookie] = useCookies(["JWT"]);
  const [states, setStates] = useState({});

  useEffect(() => {
    getProgress(cookies, setCookie, setData, data, selectionRange);
  }, [selectionRange]);

  // useEffect(() => {
  //   setSelectionRange({
  //     startDate: new Date(new Date().getTime() - 14 * 24 * 60 * 60 * 1000),
  //     endDate: new Date(new Date().getTime()),
  //     key: "selection",
  //   });
  // }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const labels = [
    "Шаг 1",
    "Шаг 2",
    "Шаг 3",
    "Шаг 4",
    "Шаг 5",
    "Шаг 6",
    "Шаг 7",
    "Шаг 8",
    "Шаг 9",
  ];
  return (
    <div style={{ display: "flex" }}>
      <BarChart labels={labels} data={data} />
      <CustomDateRangePicker
        selectionRange={selectionRange}
        setSelectionRange={setSelectionRange}
      />
    </div>
  );
}

export default HopperPage;
