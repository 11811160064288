import axios from "axios";
import refresh from "./refresh";

const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${month}-${day}-2024`;
  } else return null;
};

export default async function getCpa(
  form_id,
  cookies,
  setCookie,
  setData,
  data,
  add,
  contractors
) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/cpa?form_id=${form_id}&include_stats=true`,
        { headers }
      )
      .then((res) => {
        if (res.status == 200) {
          const translation = {
            pending: "Ожидание",
            sent: "Отправлена",
            approved: "Одобрена",
            submitted: "Выдана",
            rejected: "Отклонена",
          };
          const transformedNewData = Object.values(res.data).map((items) => {
            return items.map((item) => ({
              ...item,
              contractor: contractors?.filter(
                (obj) => obj["id"] == item["contractor_id"]
              )?.[0]?.name,
              status:
                translation[
                  item["stats"]?.[item["stats"]?.length - 1]?.["type"]
                ],
              updated_at: formatDate(
                item["stats"]?.[item["stats"]?.length - 1]?.["updated_at"]
              ),
            }));
          });
          setData(add ? transformedNewData : transformedNewData); // fix pagination
        } else if (res.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getCpa(
                form_id,
                cookies,
                setCookie,
                setData,
                data,
                add,
                contractors
              );
            else setData([]);
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        if (e.response.status == 401)
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getCpa(
                form_id,
                cookies,
                setCookie,
                setData,
                data,
                add,
                contractors
              );
            else setData([]);
          });
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT)
        getCpa(form_id, cookies, setCookie, setData, data, add, contractors);
      else setData([]);
    });
  }
}
