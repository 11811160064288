import axios from "axios";
import refresh from "./refresh";

export default async function getFormId(form_id, cookies, setCookie, setData) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/form?form_id=${form_id}&entities=conversion&entities=conversion.stats`,
        { headers }
      )
      .then((res) => {
        if (res.status == 200) {
          setData(res.data);
        } else if (res.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT) getFormId(form_id, cookies, setCookie, setData);
            else setData([]);
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        if (e.response.status == 401)
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT) getFormId(form_id, cookies, setCookie, setData);
            else setData([]);
          });
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT) getFormId(form_id, cookies, setCookie, setData);
      else setData([]);
    });
  }
}
