import axios from "axios";

export default async function refresh(cookies, setCookie) {
  const headers = {
    accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: `Bearer ${cookies.JWT}`,
  };
  await axios
    .post(`${process.env.REACT_APP_BACKEND_URL}/auth/refresh-token`, {
      headers,
    })
    .then((response) => {
      if (response.status == 200) {
        document.cookie =
          "JWT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"; // clear cookies
        setCookie("JWT", response.data["access_token"], { path: "/" });
      }
    })
    .catch((e) => {
      document.cookie = "JWT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    });
}
