import axios from "axios";
import refresh from "./refresh";

export default async function broadcast(cookies, setCookie, message) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    const data = {
      user_id: "@everyone",
      text: message,
    };
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/message/user`, data, {
        headers,
      })
      .then((res) => {
        if (res.status == 200) {
          return true;
        } else if (res.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT) broadcast(cookies, setCookie, message);
            else return false;
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        refresh(cookies, setCookie).then((res) => {
          if (cookies.JWT) broadcast(cookies, setCookie, message);
          else return false;
        });
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT) broadcast(cookies, setCookie, message);
      else return false;
    });
  }
}
