import axios from "axios";
import refresh from "./refresh";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${month}-${day}-2024`;
};

export default async function getUsers(
  cookies,
  setCookie,
  page,
  setData,
  data,
  setMaxPage
) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/users?page=${page}&per_page=25&sort=id&order=asc`,
        { headers }
      )
      .then((res) => {
        if (res.status == 200) {
          const transformedNewData = res.data["results"].map((item) => ({
            ...item,
            fullname: `${item.first_name ? item.first_name : ""} ${
              item.last_name ? item.last_name : ""
            }`,
            createdAt: formatDate(item.created_at),
          }));

          setMaxPage(res.data["max_page"]);
          setData([...data, ...transformedNewData]);
        } else if (res.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getUsers(cookies, setCookie, page, setData, data, setMaxPage);
            else setData([]);
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        if (e.response.status == 401)
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getUsers(cookies, setCookie, page, setData, data, setMaxPage);
            else setData([]);
          });
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT)
        getUsers(cookies, setCookie, page, setData, data, setMaxPage);
      else setData([]);
    });
  }
}
