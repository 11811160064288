import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import locale from "date-fns/locale/en-GB";

import { DateRange } from "react-date-range";
import { useState } from "react";

function CustomDateRangePicker({ selectionRange, setSelectionRange }) {
  function handleSelect(ranges) {
    // Extract the startDate and endDate from the selection
    const { startDate, endDate } = ranges["selection"];

    // Adjust the dates to account for the GMT+3 offset
    const offset = 3 * 60; // Offset in minutes (3 hours * 60 minutes)

    const adjustedStartDate = new Date(startDate);
    adjustedStartDate.setMinutes(adjustedStartDate.getMinutes() + offset);

    const adjustedEndDate = new Date(endDate);
    adjustedEndDate.setMinutes(adjustedEndDate.getMinutes() + offset);

    // Set the adjusted dates
    setSelectionRange({
      ...ranges["selection"],
      startDate: adjustedStartDate,
      endDate: adjustedEndDate,
    });
  }

  return (
    <DateRange
      moveRangeOnFirstSelection={false}
      editableDateInputs={true}
      ranges={[selectionRange]}
      locale={locale}
      onChange={(e) => handleSelect(e)}
    />
  );
}

export default CustomDateRangePicker;
