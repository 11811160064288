import axios from "axios";
import refresh from "./refresh";

const formatDate = (dateString, start = true) => {
  console.log(dateString.split("T")[0]);
  return (
    dateString.split("T")[0] + (start ? "T00:00:00.000Z" : "T23:59:59.000Z")
  );
};

export default async function getCpaChart(
  cookies,
  setCookie,
  setData,
  data,
  selectionRange,
  contractor,
  form_id
) {
  if (cookies.JWT) {
    const headers = {
      accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${cookies.JWT}`,
    };
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/cpa/statistics/all/date-range${
          selectionRange.startDate
            ? `?start_date=${formatDate(
                new Date(selectionRange.startDate).toISOString()
              )}`
            : ""
        }${
          selectionRange.endDate
            ? `&end_date=${formatDate(
                new Date(selectionRange.endDate).toISOString(),
                false
              )}`
            : ""
        }${contractor?.id ? `&contractor_id=${contractor.id}` : ""}${
          form_id ? `&form_id=${form_id}` : ""
        }`,
        { headers }
      )
      .then((res) => {
        if (res.status == 200) {
          setData(res.data);
        } else if (res.status == 401) {
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getCpaChart(
                cookies,
                setCookie,
                setData,
                data,
                selectionRange,
                contractor,
                form_id
              );
            else setData([]);
          });
        } else console.error(res.status);
      })
      .catch((e) => {
        if (e.response.status == 401)
          refresh(cookies, setCookie).then((res) => {
            if (cookies.JWT)
              getCpaChart(
                cookies,
                setCookie,
                setData,
                data,
                selectionRange,
                contractor,
                form_id
              );
            else setData([]);
          });
      });
  } else {
    await refresh(cookies, setCookie).then((res) => {
      if (cookies.JWT)
        getCpaChart(
          cookies,
          setCookie,
          setData,
          data,
          selectionRange,
          contractor,
          form_id
        );
      else setData([]);
    });
  }
}
